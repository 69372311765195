import Flatpickr from "stimulus-flatpickr";
// import "flatpickr/dist/themes/dark.css";
import  "flatpickr/dist/flatpickr.min.css";
import { Polish } from "flatpickr/dist/l10n/pl.js";

// creates a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {

	initialize() {
    // sets your language (you can also set some global setting for all time pickers)
    this.config = {
      locale: Polish
    };
  }

}