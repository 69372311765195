import { Controller } from "@hotwired/stimulus"
// import { get, post } from '@rails/request.js'

export default class extends Controller {
  static targets = [ "foreignerBlock", "isForeignerCbx", "peselDiv" ]  

  connect() {
    this.isForeignerCbxTarget.checked = false
    this.foreignerBlockTarget.hidden = true
    this.peselDivTarget.hidden = false    
  }

  toggleIsForeigner(e){
    this.foreignerBlockTarget.hidden = !this.isForeignerCbxTarget.checked
    this.peselDivTarget.hidden = this.isForeignerCbxTarget.checked

    const cbxCustomEvt = new CustomEvent('foreingerCbxChanged', {
        detail: this.isForeignerCbxTarget.checked
    });
    document.dispatchEvent(cbxCustomEvt);
  }

}