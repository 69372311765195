import { Controller } from "@hotwired/stimulus"
import { get, post } from '@rails/request.js'

export default class extends Controller {
  static targets = [ "sampleCode", "errorMessage", "alertTemplate", "noticeTemplate", "resultButton", "regButton" ]

  connect() {
    this.regButtonTarget.disabled = true 
    this.inputCode()   
  }

  async inputCode(event) {
    this.errorMessageTarget.innerHTML = ""
    let sampleCode = this.sampleCodeTarget.value.toUpperCase()
    if ( sampleCode.length < 5 ) {
      this.regButtonTarget.disabled = true      
      this.sampleCodeTarget.classList.remove("text-green-600")
      return
    };

    let validation = this.isCodeValid(sampleCode)

    if (validation.valid) {
      let rscValidation = await this.checkRsc(sampleCode)
      this.showRscValidation(rscValidation, sampleCode)
      this.sampleCodeTarget.classList.add("text-green-600")
    }
    else {
      this.regButtonTarget.disabled = true      
      this.sampleCodeTarget.classList.remove("text-green-600")
      const content = this.alertTemplateTarget.innerHTML.replace(/ERROR_MESSAGE_CONTENT/g, validation.message)
      this.errorMessageTarget.innerHTML = content
    }    
  }

  // javascript validation
  isCodeValid(code) {    
    let res = {code: code, valid: true, message: null}

    if (this.isEmpty(code)) {
      res = {code: code, valid: false, message: "Nie podano kodu do rejestracji"}
    }

    if (!this.validateSampleCode(code)) {
      res = {code: code, valid: false, message: "Nieprawidłowy kod do rejestracji"}
    }
    if (code.length > 5) {
      res = {code: code, valid: false, message: "Za długi kod do rejestracji"}
    }
    // console.log(res)
    return res
  }

  // DB validation
  async checkRsc(code) {
    const response = await get(`samples/code_validation/${code}`)
    let json = {}
    if (response.ok) {
      json = await response.json
      // console.log(json)   
    } 
    return json   
  }


  // HELPERS
  showRscValidation(rscValidation, code) {
    if (rscValidation.is_valid) {
      this.regButtonTarget.disabled = false            
      this.regButtonTarget.parentElement.setAttribute("action", `/registration/${code}`)
      const content = this.noticeTemplateTarget.innerHTML.replace(/MESSAGE_CONTENT/g, rscValidation.message)
      this.errorMessageTarget.innerHTML = content
    }
    else if (!rscValidation.is_valid && rscValidation.message.includes("ODBIERZ WYNIK")){
      this.regButtonTarget.disabled = true
      // this.resultButtonTarget.disabled = false
      // this.regButtonTarget.parentElement.setAttribute("action", `/registration/${code}`)
      // this.element.querySelector('input[name="sample_code"]').value = code
      
      this.resultButtonTarget.setAttribute("href", `/results/new?sample_code=${code}`)
      const content = this.noticeTemplateTarget.innerHTML.replace(/MESSAGE_CONTENT/g, rscValidation.message)
      this.errorMessageTarget.innerHTML = content
    }
    else {
      const content = this.alertTemplateTarget.innerHTML.replace(/ERROR_MESSAGE_CONTENT/g, rscValidation.message)
      this.errorMessageTarget.innerHTML = content
    }      
  }

  isEmpty(str) {
      return (!str || str.length === 0 );
  }

  validateSampleCode( code ) {    
    var sCode = code.toUpperCase().split('');
    var modulo34 = "123456789ABCDEFGHIJKLMNPQRSTUVWXYZ".split('');
    var iLen = sCode.length - 1;
    var i;
    var ulSum = 0;

    //ulSum = 0L;
    for (i = 0; i < iLen; i += 2)
        ulSum += (this.charToInt(sCode[i]) - this.charToInt("1")) * (i + 1);
    
    ulSum *= 3;
    for (i = 1; i < iLen; i += 2)
        ulSum += (this.charToInt(sCode[i]) - this.charToInt("1")) * i;

    var lastchar = sCode.slice(-1);
    var controlchar = modulo34[ulSum % 34];

    if (lastchar == controlchar) {
        return true
    } else { 
        return false
    }
  }

  charToInt(x) { 
    return x.charCodeAt(0); 
  }
}
