// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"


// Import as a Module
import cookieConsentTools from 'cookie-consent-tools';

var cookieConsentOptions = {
  cookieName: 'cookie_consent_choice',
  cookieAttributes: {
    path: '/',
    expires: 365,
    secure: true,
    domain: 'rejestracja.masdiag.pl',
    sameSite: 'strict',
  },
  consentBox: {
    autoDisplay: true,
    container: 'cookie-consent',
    type: 'message', // or 'choice'
    messages: {
        message: 'Stosujemy pliki cookie w celu świadczenia naszych usług. Korzystając z naszego serwisu wyrażasz zgodę na ich używanie.',
        okButton: 'OK'
    }
  }
};

cookieConsentTools.initialize(cookieConsentOptions);
